import { Col, Row, Select } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import EDITER_ICON from '../../assets/icons/Editor.svg'
import DELETE_ICON from '../../assets/icons/Supprimer.svg'
//import DUPLICATE_ICON from '../../assets/icons/duplicate.svg'
import { ActionSelectionType } from '../../enum/ActionSelectionType'
import { useTranslation } from 'react-i18next'

interface ActionSelectionProps {
  //selected id of rows
  selectedIds: string[]
  handleDelete: () => void
  handleEdit: () => void
  handleSelect: (value: string) => void
  //selected option of action dropdown
  selectedOption?: string
}

interface ListOptionInterface {
  disabled: boolean
  value: ActionSelectionType
  label: ReactNode
}

const ActionSelection = (props: ActionSelectionProps) => {
  const { t } = useTranslation()
  const {
    selectedIds = [],
    handleDelete,
    handleEdit,
    handleSelect,
    selectedOption,
  } = props
  const multipleSelectedOption = [ActionSelectionType.DELETE]

  const renderOption = (props: { src: string; type: ActionSelectionType }) => {
    return (
      <Row gutter={8} align={'middle'}>
        <Col>
          <img src={props.src} width={15} alt="" style={{ marginTop: 5 }} />
        </Col>
        <Col
          className={`font-medium ${
            props.type === ActionSelectionType.DELETE
              ? 'text-[#F25524]'
              : 'text-[#808080]'
          }`}
        >
          {props.type}
        </Col>
      </Row>
    )
  }

  const listOption: ListOptionInterface[] = [
    {
      disabled: true,
      value: ActionSelectionType.EDIT,
      label: renderOption({ src: EDITER_ICON, type: ActionSelectionType.EDIT }),
    },
    // {
    //   disabled: true,
    //   value: ActionSelectionType.DUPLICATE,
    //   label: renderOption({
    //     src: DUPLICATE_ICON,
    //     type: ActionSelectionType.DUPLICATE,
    //   }),
    // },
    {
      disabled: true,
      value: ActionSelectionType.DELETE,
      label: renderOption({
        src: DELETE_ICON,
        type: ActionSelectionType.DELETE,
      }),
    },
  ]

  const listOptionRendered = listOption.map((el) => ({
    ...el,
    disabled:
      selectedIds.length === 0
        ? true
        : selectedIds.length === 1
        ? false
        : !multipleSelectedOption.includes(el.value),
  }))

  const onSelectOption = (value: string) => {
    handleSelect(value)
    switch (value) {
      case ActionSelectionType.DUPLICATE: {
        break
      }
      case ActionSelectionType.EDIT: {
        handleEdit()
        break
      }
      case ActionSelectionType.DELETE: {
        handleDelete()
        break
      }
      default:
        return
    }
  }

  return (
    <Select
      value={selectedOption}
      style={{ width: 165, marginRight: -40 }}
      options={listOptionRendered}
      onSelect={onSelectOption}
      placeholder={<span className="italic capitalize">{t('actions')}</span>}
    />
  )
}

export default ActionSelection
