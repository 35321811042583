import moment from 'moment'
import { Societe, SocieteDataType } from '../../models'
import { t } from 'i18next'

export const makeTableValue = (array: Societe[]) => {
  let list: SocieteDataType[] = []
  if (array) {
    array.forEach((e, index) => {
      list.push(makeRowParentValue(e, index))
    })
  }
  return list
}
const makeRowParentValue = (societe: Societe, index: number) => {
  let createAt = (societe?.create_at || 0) * 1000
  let updateAt = (societe?.update_at || 0) * 1000
  let value: SocieteDataType = {
    id: societe.id,
    key: index,
    select: true,
    nom: societe.nom || '',
    code: societe.code || '',
    warehouses: societe.Warehouses,
    create_at: moment(createAt).format(t('time-format')),
    update_at: moment(updateAt).format(t('time-format')),
  }
  return value
}
