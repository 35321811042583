import { Tooltip } from 'antd'
import { useState } from 'react'
import {
  Conditionnement,
  RefFournisser,
  ClientShorter,
  CompanyShorter,
  WarehouseShorter,
  ClientCompanyWareHouse,
} from '../../../models'

interface MultiItemSectionInterface {
  data:
    | Conditionnement[]
    | RefFournisser[]
    | ClientShorter[]
    | CompanyShorter[]
    | WarehouseShorter[]
}

const mapType = (
  data:
    | Conditionnement[]
    | RefFournisser[]
    | ClientShorter[]
    | CompanyShorter[]
    | WarehouseShorter[]
) => {
  let name_nom_or_fnsId = []
  let items
  if (data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if ('nom' in data[i]) {
        items = data[i] as Conditionnement | WarehouseShorter
        name_nom_or_fnsId.push(items.nom || '')
      }
      if ('code_nom' in data[i]) {
        items = data[i] as CompanyShorter | ClientShorter
        name_nom_or_fnsId.push(items.code_nom || '')
      }
      if ('company_code_nom' in data[i]) {
        items = data[i] as ClientCompanyWareHouse
        name_nom_or_fnsId.push(items.company_code_nom || '')
      }
      if ('warehouse_code_nom' in data[i]) {
        items = data[i] as ClientCompanyWareHouse
        name_nom_or_fnsId.push(items.warehouse_code_nom || '')
      } else {
        items = data[i] as RefFournisser
        name_nom_or_fnsId.push(items.fournisser_id || '')
      }
    }
  }
  return name_nom_or_fnsId
}

const MultiItemSection = (props: MultiItemSectionInterface) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  let res = mapType(props.data)

  return (
    <>
      {props.data.length === 0 ? (
        <div>-</div>
      ) : props.data.length > 1 ? (
        <Tooltip
          title={<MultiItemSectionTooltip data={props.data} />}
          color="#FFF"
          overlayInnerStyle={{ borderRadius: 0, color: '#000' }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
        >
          <div
            className="rounded-full text-white bg-secondary text-center"
            style={{ height: 23, width: 23 }}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          >
            <div
              className="flex justify-center items-center font-bold"
              style={{ height: 23, fontSize: 15 }}
              data-testid="multi-item-multi"
            >
              {props.data.length}
            </div>
          </div>
        </Tooltip>
      ) : (
        <div data-testid="multi-item-single">{res}</div>
      )}
    </>
  )
}

export default MultiItemSection

const MultiItemSectionTooltip = (props: MultiItemSectionInterface) => {
  let res = mapType(props.data)
  return (
    <div>
      {res.map((item, idx) => {
        return <p key={idx}>{item}</p>
      })}
    </div>
  )
}
