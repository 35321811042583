import { Form, Input, Pagination, Table, notification } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import CLOSE_ICON from '../../../assets/icons/close.svg'
import SEARCH_ICON from '../../../assets/icons/search.svg'
import ERROR from '../../../assets/images/error.png'
import ActionSelection from '../../../components/Common/ActionSelection'
import CustomDropdown from '../../../components/Common/CustomDropdown/CustomDropdown'
import SelectRender from '../../../components/Common/DataType/SelectType'
import MultiItemSection from '../../../components/Common/MultiItemSection/MultiItemSection'
import { PagingEnum } from '../../../enum'
import useResizeScreen from '../../../hook/useResizeScreen'
import societeApi from '../../../http/societeApi'
import { SocieteDataType } from '../../../models'
import { getAllSociete } from '../../../redux/reducers/societeSlice'
import { makeTableValue } from '../../../utils/societe/makeTableValue'
import NewActionRow from '../../stock/components/ActionRow/NewActionRow'
import './Societe.scss'
import CustomPopup from '../../../components/Common/CustomPopup/CustomPopup'
import { popupType } from '../../../enum/popupType'
import useSocieteHook from './useSocieteHook'

const SocieteScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const dispatch = useDispatch()
  const { currentListSociete } = useSelector(
    (state: RootState) => state.societe
  )
  const [isShowLess, setIsShowLess] = useState<boolean>(false)
  const [data, setData] = useState<SocieteDataType[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  let height = useResizeScreen({
    pixels: 15,
  })
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>()
  const { openPopupDelete, setOpenPopupDelete } = useSocieteHook()
  const [selectedOption, setSelectedOption] = useState<string>()
  const columns: ColumnsType<SocieteDataType> = [
    {
      title: <div className="text-center">Select</div>,
      dataIndex: 'select',
      key: 'select',
      width: 80,
      fixed: 'left',
      render: (_, row) => {
        return (
          <SelectRender
            isMainLine={true}
            isCheck={selectedRowKeys.includes(row.id || '')}
            onChangeChecked={(e) => {
              if (e.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, row.id || ''])
              } else {
                setSelectedRowKeys(
                  [...selectedRowKeys].filter((i) => i !== row.id)
                )
              }
            }}
            screenColor="blue"
          />
        )
      },
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      width: 520,
      render: (_, row) => {
        return <>{row.nom}</>
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 180,
      render: (_, row) => {
        return <>{row.code}</>
      },
    },
    {
      title: 'Entrepôt(s)',
      dataIndex: 'entrepot',
      key: 'entrepot',
      width: 680,
      render: (_, row) => {
        return <MultiItemSection data={row.warehouses || []} />
      },
    },
    {
      title: 'Mise à jour',
      dataIndex: 'updateAt',
      key: 'updateAt',
      width: 380,
      render: (_, row) => {
        return <>{row.update_at}</>
      },
    },
  ]

  const rowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-main-even' : 'table-row-main-odd'
  }

  useEffect(() => {
    dispatch(
      getAllSociete({
        limit: Number(pageSize) || 25,
        offset: (Number(pageIndex) - 1) * Number(pageSize) || 0,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize])

  useEffect(() => {
    const list = makeTableValue(currentListSociete.societeList)
    setData(list)
  }, [currentListSociete, pageSize, pageIndex])

  const onOpenModal = () => {
    navigate(
      `/configurations/societe/societe-create?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  const onClosePopupDelete = () => {
    setOpenPopupDelete(false)
    setSelectedOption(undefined)
  }

  const handleDeleteSociete = async () => {
    setIsLoading(true)
    setOpenPopupDelete(false)
    try {
      await societeApi.deleteMultipleSociete({ ids: selectedRowKeys })
      dispatch(
        getAllSociete({
          limit: Number(pageSize) || 25,
          offset: (Number(pageIndex) - 1) * Number(pageSize) || 0,
        })
      )
      setSelectedRowKeys([])
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      notification.open({
        className: 'noti noti-error',
        message: (
          <div className="flex items-center">
            <img src={ERROR} alt="error" width={50} className="mr-2.5" />
            {t('canNotDelete')}
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setSelectedOption(undefined)
    }
  }

  const handleEditSociete = () => {
    if (selectedRowKeys.length === 1)
      navigate(
        `/configurations/societe/societe-edit?societe-id=${selectedRowKeys[0]}&page-index=${pageIndex}&page-size=${pageSize}`
      )
  }

  return (
    <div className="config-table-style">
      <div className="relative text-xs">
        <div
          className="research-bar bg-[#EEEEEE] mx-5 px-5 py-2 flex flex-row justify-between items-center"
          style={{
            borderBottomRightRadius: 7,
            borderBottomLeftRadius: 7,
          }}
        >
          <div className="grid grid-cols-7 gap-x-4 w-full">
            <Form.Item noStyle label="" name="" colon={false}>
              <Input placeholder="Nom Société" className="research-bar-input" />
            </Form.Item>
            <Form.Item noStyle label="" name="" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Tous entrepôts"
                className="research-bar-input"
              />
            </Form.Item>
            <div className="flex flex-row gap-x-2">
              <img src={SEARCH_ICON} alt="masques" />
              <img src={CLOSE_ICON} alt="masques" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-10 flex items-center justify-between">
        <div className="py-2 page-title-heading-1">Sociétés</div>
        <div className="flex items-center">
          {selectedRowKeys.length >= 1 && (
            <ActionSelection
              selectedIds={selectedRowKeys}
              handleDelete={() => setOpenPopupDelete(true)}
              handleEdit={handleEditSociete}
              handleSelect={(value) => setSelectedOption(value)}
              selectedOption={selectedOption}
            />
          )}
          <NewActionRow
            onClickPlus={onOpenModal}
            isClickUp={false}
            isHideExport
          />
        </div>
      </div>
      <CustomPopup
        title={t('confirmDelete')}
        type={popupType.DELETE}
        isOpen={openPopupDelete}
        onConfirm={handleDeleteSociete}
        onClose={onClosePopupDelete}
      >
        <p className="text-lg text-[#505050] my-10">{`${t('sureToDelete')} ${t(
          'company'
        )}?`}</p>
      </CustomPopup>
      <div className="pl-10">
        <Table
          style={height}
          className="config-table config-table-wrapper overflow-y-auto relative text-[#EBEBEB]"
          columns={columns}
          pagination={false}
          indentSize={0}
          loading={currentListSociete.loading || isLoading}
          dataSource={data}
          rowClassName={rowClassName}
        />
      </div>
      <Pagination
        className="custom-pagination reference-pagination table-wrapper"
        style={{ margin: '20px auto' }}
        locale={{ items_per_page: '' }}
        total={currentListSociete.metadata.total || 0}
        defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
        showLessItems={isShowLess}
        pageSizeOptions={[
          PagingEnum.DEFAULT_PAGE_SIZE,
          PagingEnum.PAGE_SIZE_1,
          PagingEnum.PAGE_SIZE_2,
        ]}
        pageSize={Number(pageSize)}
        current={Number(pageIndex)}
        showSizeChanger
        showTotal={(total, range) =>
          `${range[0] >= 0 ? range[0] : 0}-${
            range[1] >= 0 ? range[1] : 0
          } sur ${total}`
        }
        onChange={(page, size) => {
          navigate(
            `/configurations/societe?page-index=${page}&page-size=${size}`
          )
          setIsShowLess(() => {
            return page > 4
          })
        }}
      />
    </div>
  )
}

export default SocieteScreen
